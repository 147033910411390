
#game-menu-container {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: translate3d(0,0,0); /* eliminate safari transform render bug */
}

#game-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.floating-menu-item.fade-in-out {
    transition: opacity 0.3s;
}



#game-menu-back-button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}
#game-menu-back-button svg {
    margin-right: 0.5em;
}


.close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    cursor: pointer;
}


#how-to-play-button {
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    transition: opacity 0.3s linear;
}

#how-to-play-box {
    background: url('/images/pine.png');
    border: solid 0.2rem black;
    border-radius: 1.5rem;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);

    font-size: initial;
    width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
                opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

#how-to-play-box.hidden {
    transform: translate(-50%, 40%);
}

#htp-info {
    overflow-y: auto;
}

#how-to-play-box h3,
#how-to-play-box h4,
#how-to-play-box h5,
#how-to-play-box p {
    margin-bottom: 1rem;
}

#how-to-play-box li * {
    margin-bottom: 0.3rem;
}

#how-to-play-box h3 { font-size: 2rem; }
#how-to-play-box h4 { font-size: 1.5rem; }
#how-to-play-box h5 { font-size: 1.25rem; }

#htp-info > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

#how-to-play-box img.queen-img {
    align-self: center;
    width: 8rem;
    height: 10rem;
}

#how-to-play-box ul, #how-to-play-box h5 {
    padding-left: 2rem;
}

@media screen and (max-width: 600px) {
    #htp-info > div {
        flex-direction: column-reverse;
    }
    #how-to-play-box h5 {
        padding-left: 0;
    }
    #how-to-play-box img.queen-img {
        margin-top: 1rem;
    }
}


#credits-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
}

#credits-box {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 45ch;
    transform: translate(-50%, -50%);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
                opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    background: url("/images/pine.png");
    border: solid 0.2rem black;
    border-radius: 1.5rem;
    padding: 1.5rem;

    text-align: center;
    user-select: text;
}
#credits-box img { user-select: none; }
#credits-box.hidden {
    transform: translate(-50%, -60%);
}


#game-menu-start-button {
    font-size: 2.5rem;
    padding: 1rem 6rem;
}


#flip-board-checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin: 1rem;
}

#flip-board-checkbox-container .checkbox-label {
    color: white;
}

#flip-board-checkbox {
    margin-right: 1rem;
}


#options-menu-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    transition: opacity 0.3s 0s;
}
#options-menu-button svg {
    margin-left: 0.5em;
}

#options-menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
                top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    max-width: 100%;
    background: url("/images/pine.png");
    border: solid 0.2rem black;
    border-radius: 1.5rem;
    padding: 3rem;
}

#options-menu.fade-in-out {
    /* add opacity to transition */
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
                top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
                opacity 0.3s linear;
}

#options-menu-button svg {
    vertical-align: bottom;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: rotate(0deg);
}

#options-menu-button svg.flipped {
    transform: rotate(180deg);
}

#options-menu.tucked-away {
    transform: translate(-50%, 0);
    top: 110%;
}

#options-menu h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

#options-menu h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
}

#options-menu .radioish-button-panel {
    height: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 4rem;
}

#options-menu .radioish-button {
    display: inline-block;
    width: 6em;
    height: 100%;
    cursor: pointer;
}

#options-menu .radioish-button.wood-theme {
    background: url(/images/mediumcherry.png);
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    border: solid 0.2rem black;
    border-right-width: 0.1rem;
}

#options-menu .radioish-button.wood-theme:active {
    background: url(/images/darkcherry.png);
}

#options-menu .radioish-button.green-theme {
    background: #80a080;
    border: solid 0.2rem black;
    border-left-width: 0.1rem;
    border-right-width: 0.1rem;
}

#options-menu .radioish-button.green-theme:active {
    background: #407040;
}

#options-menu .radioish-button.blue-theme {
    background: #7baae1;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border: solid 0.2rem black;
    border-left-width: 0.1rem;
}

#options-menu .radioish-button.blue-theme:active {
    background: #1070bf;
}

#options-menu .radioish-button-panel .arrow {
    content: '';
    width: 0;
    height: 0;
    border: solid 0.7rem transparent;
    border-bottom-color: black;
    position: absolute;
    bottom: -1rem;
    left: 16.66%;
    transform: translateX(-50%);
    transition: left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


#options-menu .flex-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
